import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

// components
import ECoachPage from 'components/pageECoach/_index';
import JourneyIdPage from 'components/pageJourneyId/_index';
import JourneyListPage from 'components/pageJourneyList/_index';
import NotFound from 'components/page404/page404';
import Onboarding from 'components/pageOnboarding/_index';
import DocumentPage from 'components/pageECoach/documentDisplay/DocumentPage';
import SlideshowPage from 'components/pageSlideshow/_index';

// context
import { useUser } from 'contexts/UserContext';

// constants
import routesConfig from 'constants/routesConfig.json';
import userRoles from 'constants/userRoles.json';

function PageRoutes() {
  const { data: user, userRole } = useUser();

  if (!userRole || !user) return null;
  return (
    <Switch>
      <Route exact path={routesConfig.ROOT_PAGE}>
        {userRole === userRoles.MEMBER &&
        user.member?.onboarding_completed_at === null ? (
          <Redirect to={routesConfig.ONBOARDING_PAGE} />
        ) : (
          <Redirect to={routesConfig.E_COACH_PAGE} />
        )}
      </Route>

      <Route exact path={`${routesConfig.E_COACH_ABOUT_PAGE}`}>
        <ECoachPage />
      </Route>

      <Route path={`${routesConfig.E_COACH_ALL_CHATS_PAGE}`}>
        <ECoachPage />
      </Route>

      <Route exact path={`${routesConfig.E_COACH_PAGE}/:id?`}>
        <ECoachPage />
      </Route>

      <Route exact path={`${routesConfig.DOCUMENT_PAGE}/:id/pdf`}>
        <DocumentPage />
      </Route>

      <Route exact path={routesConfig.JOURNEY_LIST_PAGE}>
        <JourneyListPage />
      </Route>

      <Route exact path={`${routesConfig.JOURNEY_ID_PAGE}/:id`}>
        <JourneyIdPage />
      </Route>

      <Route exact path={routesConfig.SLIDESHOW_PAGE}>
        <SlideshowPage />
      </Route>

      <Route exact path={routesConfig.ONBOARDING_PAGE}>
        <Onboarding />
      </Route>

      <Route component={NotFound} exact path="*" />
    </Switch>
  );
}

export default PageRoutes;
