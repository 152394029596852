import styled from 'styled-components';
import { Link } from 'react-router-dom';
import colours from 'constants/colours';
import device from 'constants/devices';
import shadows from 'constants/dropShadows';

export const StyledLink = styled.a`
  box-sizing: border-box;
  color: ${colours.blue[500]};
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  text-decoration: none;
`;

export const RouterLink = styled(Link)``;

export const StyledCard = styled.div`
  align-items: center;
  background-color: ${colours.shade[0]};
  border-radius: 30px;
  box-shadow: ${shadows.M};
  display: flex;
  gap: 15px;
  min-width: 500px;
  padding: 30px;
  width: fit-content;

  svg path {
    stroke: ${colours.neutralCool[500]};
  }

  @media (max-width: ${device.mobileL}) {
    min-width: unset;
    width: 100%;
  }
`;

export const Button = styled('div')`
  align-items: center;
  color: ${colours.neutralCool[500]};
  cursor: pointer;
  display: flex;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
  justify-content: center;
  gap: 5px;
  line-height: 18px;
  max-height: 44px;
  padding: 10px 0;
  user-select: none;
  width: fit-content;

  svg {
    height: 15;
    width: 15;
    stroke-width: 2.5;
  }

  svg path {
    stroke: ${colours.neutralCool[500]};
  }

  &:hover {
    color: ${colours.primary[500]};

    svg path {
      stroke: ${colours.primary[500]};
    }
  }
`;

export const Row = styled('div')`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${device.mobileL}) {
    flex-direction: column;
  }
`;
