import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

// components
import ApiLoading from 'components/_pageLayout/ApiLoading';
import ErrorBoundary from 'components/_pageLayout/ErrorBoundary';
import MemberJourneyId from 'components/pageJourneyId/memberView/_index';

// hooks
import useApi from 'hooks/useApi';

export default function JourneyIdPage() {
  const { data, callApi: getJourney } = useApi();
  const params = useParams();

  const { id } = params;

  useEffect(() => {
    getJourney(`/members/journeys/${id}`);
  }, [getJourney, id]);

  if (data.status === 'loading') return <ApiLoading />;
  return (
    <ErrorBoundary>
      <MemberJourneyId journey={data.response} />
    </ErrorBoundary>
  );
}
