import styled from 'styled-components';
import { Link } from 'react-router-dom';
import colours from 'constants/colours';
import device from 'constants/devices';
import shadows from 'constants/dropShadows';
import * as Text from 'components/_styles/Text.style';

export const Section = styled('div')`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  max-width: 1200px;
  padding: 0 30px;
  width: 100%;

  @media (max-width: ${device.tablet}) {
    padding: 10px 20px;
  }
`;

export const Container = styled('div')`
  align-items: center;
  background-color: white;
  border-radius: 30px;
  box-shadow: ${shadows.XS};
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1200px;
  padding: 20px 30px;
  width: 100%;

  @media (max-width: ${device.tablet}) {
    padding: 10px 20px;
  }
`;

export const Divider = styled('div')`
  border-left: 1px solid ${colours.neutralCool[200]};
  height: 100%;
  margin: 0 10px;

  @media (max-width: ${device.mobileM}) {
    margin: 0 5px;
  }
`;

export const TopbarLogo = styled('div')`
  img {
    padding: 0 12px;
  }

  @media (max-width: ${device.laptop}) {
    align-items: center;
    display: flex;
  }

  @media (max-width: ${device.mobileM}) {
    display: none;
  }
`;

export const RightSide = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 20px;
  height: 100%;
  justify-content: flex-end;

  @media (max-width: ${device.mobileL}) {
    flex: 1;
    justify-content: space-around;
  }
`;

export const Tab = styled('div')`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 10px;
  padding: 5px 10px;
  transition: 0.1s;

  svg path {
    stroke: ${(props) => props.active === 'true' && colours.primary[600]};
  }

  @media (max-width: ${device.tablet}) {
    display: none;
    margin: 0;
    padding: 5px 15px;
  }

  @media (max-width: ${device.mobileL}) {
    justify-content: center;
    padding: 5px 5px;
  }
`;

export const NavLink = styled(Link)`
  align-items: center;
  display: flex;
  gap: 10px;
  text-decoration: none;

  @media (max-width: ${device.tablet}) {
    gap: 0px;
    flex-direction: column;
  }
`;

export const TabText = styled(Text.P1Semibold)`
  color: ${(props) =>
    props.active === 'true' ? colours.primary[600] : colours.neutralCool[500]};

  @media (max-width: ${device.tablet}) {
    font-size: 10px;
  }
`;

export const TabIcon = styled('div')`
  svg {
    height: 23px;
    width: 23px;
  }

  svg path {
    fill: ${(props) =>
      props.active === 'true'
        ? colours.primary[600]
        : colours.neutralCool[500]};
    stroke: ${(props) =>
      props.active === 'true'
        ? colours.primary[600]
        : colours.neutralCool[500]};
  }
`;

export const Logout = styled('div')`
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 10px;
  margin-right: 10px;
`;

export const LogoutText = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 3px;

  @media (max-width: ${device.tablet}) {
    display: none;
  }
`;
