import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// assets
import { General } from 'untitledui-js';

// styles
import * as AllChats from 'components/pageECoach/_styles/AllChats.style';
import * as Text from 'components/_styles/Text.style';

export default function Status({ chat }) {
  const { t } = useTranslation('pageEcoach');

  switch (chat.resolution_status) {
    case 'active':
      return <AllChats.Button>{t('chatStatus.Update now')}</AllChats.Button>;

    case 'resolved':
      return (
        <AllChats.Status status={chat.resolution_status}>
          <General.CheckCircleBroken />
          <Text.P2Semibold>{t('chatStatus.Resolved')}</Text.P2Semibold>
        </AllChats.Status>
      );

    default:
      return (
        <AllChats.Status status={chat.resolution_status}>
          <General.CheckCircleBroken />
          <Text.P2Semibold>{t('chatStatus.No action needed')}</Text.P2Semibold>
        </AllChats.Status>
      );
  }
}

Status.propTypes = {
  chat: PropTypes.object,
};
