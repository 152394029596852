import PropTypes from 'prop-types';
import React from 'react';
import dayjs from 'dayjs';

// components
import DividerWithText from 'components/pageECoach/cardTemplates/DividerWithText';

// hooks
import useUserLanguage from 'hooks/useUserLanguage';

function ChatDatestamp({ index, timestamp, prevTimestamp }) {
  const lang = useUserLanguage();
  dayjs.locale(lang);

  const format = lang === 'en' ? 'ddd, MMM D, YYYY' : 'ddd D MMM YYYY';
  const timestampString = dayjs(timestamp).locale(lang).format(format);
  const prevTimestampString = dayjs(prevTimestamp).locale(lang).format(format);

  if (timestampString === prevTimestampString || index === 0) return <></>;
  return <DividerWithText text={timestampString} />;
}

export default ChatDatestamp;

ChatDatestamp.propTypes = {
  index: PropTypes.number,
  timestamp: PropTypes.string,
  prevTimestamp: PropTypes.string,
};
