import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// constants
import routesConfig from 'constants/routesConfig.json';

// components
import Card from 'components/pageECoach/insights/Card';
import TopCard from 'components/pageECoach/insights/TopCard';
import Loader from 'components/pageECoach/loaders/Loader';
import { Stack } from '@mui/material';

// hooks
import useApi from 'hooks/useApi';

// styles
import * as Styled from 'components/pageECoach/_styles/Insights.style';
import * as Text from 'components/_styles/Text.style';

export default function Insights() {
  const { data: insights, callApi: getInsights } = useApi();
  const { t } = useTranslation('pageEcoach');

  useEffect(() => {
    getInsights(`${routesConfig.ECOACH.INSIGHTS}?days=30`);
  }, [getInsights]);

  if (insights.status === 'loading') return <Loader inline />;
  if (insights.status !== 'success')
    return (
      <Styled.TopCard>
        <Text.P3>
          {t(
            'insights.Your insights cannot be loaded right now. Please, try again later.'
          )}
        </Text.P3>
      </Styled.TopCard>
    );

  return (
    <Stack spacing={4}>
      <TopCard
        onboardingRate={insights.response.onboarding_rate}
        sessionCount={insights.response.session_count}
        recentSessionsCount={insights.response.recent_sessions_count}
      />
      <Styled.TeamCardSection>
        {insights.response.insights.map((insight) => (
          <Card key={insight.site} insight={insight} />
        ))}
      </Styled.TeamCardSection>
    </Stack>
  );
}
