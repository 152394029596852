import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// components
import IntroCard from 'components/pageECoach/aboutECoach/IntroCard';
import PrivacyCard from 'components/pageECoach/aboutECoach/PrivacyCard';
import ChatBubble from 'components/pageECoach/ChatBubble';

import * as Text from 'components/_styles/Text.style';

export default function AboutECoachCard() {
  const { t } = useTranslation('pageEcoach');
  const [showIntro, setShowIntro] = useState(true);
  const [showPrivacy, setShowPrivacy] = useState(false);

  const onBackClick = () => {
    setShowIntro(true);
    setShowPrivacy(false);
  };

  const onPrivacyClick = () => {
    setShowIntro(false);
    setShowPrivacy(true);
  };

  const navigation = showPrivacy
    ? {
        action: () => onBackClick(),
        text: t('aboutECoach.privacy.back'),
      }
    : null;

  const appVersion = localStorage.getItem('app-version');

  return (
    <ChatBubble header={t('aboutECoach.header')} navigation={navigation}>
      {showIntro && <IntroCard onPrivacyClick={onPrivacyClick} />}
      {showPrivacy ? (
        <PrivacyCard />
      ) : (
        <Text.P2Semibold style={{ marginLeft: 'auto' }}>
          {`Version ${appVersion}`}
        </Text.P2Semibold>
      )}
    </ChatBubble>
  );
}
