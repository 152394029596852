import styled from 'styled-components';
import colours from 'constants/colours';
import device from 'constants/devices';
import * as Text from 'components/_styles/Text.style';

export const LanguageTextMobile = styled(Text.P1Semibold)`
  color: ${colours.neutralCool[500]};
  display: none;

  @media (max-width: ${device.tablet}) {
    display: flex;
  }
`;

export const LanguageToggleDesktop = styled('div')`
  align-items: center;
  display: flex;
  gap: 10px;

  @media (max-width: ${device.tablet}) {
    display: none;
  }
`;

export const LanguageText = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;
