import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

// assets
import { Arrow } from 'untitledui-js';

// components
import DetailsCard from 'components/pageJourneyId/memberView/DetailsCard';
import ErrorBoundary from 'components/_pageLayout/ErrorBoundary';
import MicrolearningCardMember from 'components/pageJourneyId/memberView/MicrolearningCard';
import Tabs from 'components/pageJourneyId/memberView/Tabs';

// constants
import routesConfig from 'constants/routesConfig.json';

// hooks
import useUserLanguage from 'hooks/useUserLanguage';

// styles
import * as Styled from 'components/pageJourneyId/_styles/_index.style';
import * as Text from 'components/_styles/Text.style';

export default function MemberJourneyId({ journey }) {
  const lang = useUserLanguage();
  const history = useHistory();

  const [activeTab, setActiveTab] = useState('microlearnings');
  const toggleTab = (tab) => setActiveTab(tab);

  const handleRedirect = useCallback(() => {
    history.push(routesConfig.JOURNEY_LIST_PAGE);
  }, [history]);

  const journeyTitle = journey[`title_${lang}`];
  return (
    <Styled.Page>
      <Styled.PageContent>
        <Styled.Header>
          <Styled.Cover>
            <Styled.CoverImage src={journey.cover} />
          </Styled.Cover>
          <Styled.Top>
            <Styled.BackButton onClick={handleRedirect}>
              <Arrow.ChevronLeft />
            </Styled.BackButton>
          </Styled.Top>
          <Styled.InfoSection>
            {journeyTitle && <Text.H1Bold>{journeyTitle}</Text.H1Bold>}
          </Styled.InfoSection>
        </Styled.Header>
        <Styled.Content>
          <Tabs activeTab={activeTab} toggleTab={toggleTab} />
          <Styled.TabContent>
            <ErrorBoundary>
              {activeTab === 'microlearnings' &&
                journey.microlearnings.map((microlearning) => (
                  <MicrolearningCardMember
                    cover={journey.cover}
                    journey={journey}
                    key={microlearning.id}
                    microlearning={microlearning}
                  />
                ))}
              {activeTab === 'details' && <DetailsCard journey={journey} />}
            </ErrorBoundary>
          </Styled.TabContent>
        </Styled.Content>
      </Styled.PageContent>
    </Styled.Page>
  );
}

MemberJourneyId.propTypes = {
  journey: PropTypes.object,
};
