import styled from 'styled-components';
import * as Cards from 'components/_styles/Cards.style';
import device from 'constants/devices';
import shadows from 'constants/dropShadows';

export const Card = styled(Cards.BasicCard)`
  border-radius: 40px;
  box-shadow: ${shadows.M};
  box-sizing: unset;
  flex-direction: unset;
  flex-grow: 1;
  justify-content: unset;
  max-width: unset;
  min-height: 328px;
  overflow: hidden;
  padding: unset;
  width: 100%;

  @media (max-width: ${device.tablet}) {
    border-radius: 30px;
    flex-direction: column;
  }
`;

export const Cover = styled('div')`
  width: 35%;

  @media (max-width: ${device.tablet}) {
    height: 160px;
    width: 100%;
  }
`;

export const CoverImage = styled('img')`
  border-radius: 38px 0 0 38px;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  width: 100%;

  @media (max-width: ${device.tablet}) {
    border-radius: 38px 38px 0 0;
  }
`;

export const Details = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const InfoSection = styled('div')`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  gap: 30px;
  padding: 40px 40px 40px 40px;
  width: 65%;

  @media (max-width: ${device.tablet}) {
    gap: 20px;
    padding: 30px;
    width: 100%;
  }

  @media (max-width: ${device.mobileL}) {
    padding: 20px;
  }
`;

export const Row = styled('div')`
  align-items: center;
  display: flex;
  gap: 15px;

  @media (max-width: ${device.tablet}) {
    gap: 10px;
  }
`;
