import styled from 'styled-components';
import device from 'constants/devices';
import * as Text from 'components/_styles/Text.style';

export const AvatarArea = styled('div')`
  align-items: center;
  display: flex;
  gap: 8px;
  padding: 7px;
`;

export const Avatar = styled('div')`
  align-items: center;
  border-radius: 100px;
  display: flex;
  height: 32px;
  justify-content: center;
  position: relative;
  width: 32px;
`;

export const AvatarFiller = styled('div')`
  background-color: gray;
  border-radius: 100px;
  height: 32px;
  width: 32px;
`;

export const AvatarName = styled(Text.P2Semibold)`
  max-width: 8em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Bottom = styled('div')`
  align-items: start;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
`;

export const LogoIcon = styled('img')`
  cursor: pointer;
  display: none;

  @media (max-width: ${device.tablet}) {
    display: block;
    height: 22px;
    padding: 12px 12px 30px 12px;
  }
`;

export const LogoText = styled('img')`
  box-sizing: unset;
  cursor: pointer;
  height: 22px;
  padding: 12px 12px 30px;

  @media (max-width: ${device.tablet}) {
    display: none;
  }
`;

export const OnboardingLogo = styled('div')`
  background-color: white;
  border-bottom: 2px solid #f2f2f0;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 66px;
  justify-content: space-between;
  min-width: unset;
  max-width: unset;
  padding: 18px;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 10;

  img {
    padding: 5px 12px;
  }
`;

export const Icon = styled('img')`
  height: 22px;
`;
