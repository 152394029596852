import React, { useEffect, useState } from 'react';
import { usePostHog } from 'posthog-js/react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';

// assets
import { Communication, Files, General, MediaDevices } from 'untitledui-js';

// constants
import routesConfig from 'constants/routesConfig.json';

// context
import { useUser } from 'contexts/UserContext';

// styles
import * as Styled from 'components/pageECoach/_styles/Toolbar.style';

export default function Toolbar(props) {
  const { activeSession, chatGuideData, handleNewChat, handleClickedPage } =
    props;

  const posthog = usePostHog();
  const { data: user } = useUser();
  const { t } = useTranslation('pageEcoach');
  const history = useHistory();
  const location = useLocation();
  const pathname = location.pathname.toLowerCase();

  const [activePage, setActivePage] = useState('chat');

  useEffect(() => {
    if (
      [
        routesConfig.E_COACH_ABOUT_PAGE,
        routesConfig.E_COACH_ALL_CHATS_PAGE,
        routesConfig.E_COACH_INSIGHTS_PAGE,
      ].includes(pathname)
    )
      return setActivePage(pathname);

    return setActivePage(routesConfig.E_COACH_PAGE);
  }, [pathname, setActivePage]);

  const handleToolbarClick = (tab) => {
    posthog?.capture(`user_clicks_${tab}_in_toolbar`);
    setActivePage(tab);
    handleNewChat();

    switch (tab) {
      case 'new_chat':
        setActivePage('new_chat');
        handleClickedPage(routesConfig.E_COACH_PAGE);
        return history.push(routesConfig.E_COACH_PAGE);

      case routesConfig.E_COACH_ALL_CHATS_PAGE:
        setActivePage(routesConfig.E_COACH_ALL_CHATS_PAGE);
        handleClickedPage(routesConfig.E_COACH_ALL_CHATS_PAGE);
        return history.push(routesConfig.E_COACH_ALL_CHATS_PAGE);

      case routesConfig.E_COACH_ABOUT_PAGE:
        setActivePage(routesConfig.E_COACH_ABOUT_PAGE);
        handleClickedPage(routesConfig.E_COACH_ABOUT_PAGE);
        return history.push(routesConfig.E_COACH_ABOUT_PAGE);

      case routesConfig.E_COACH_INSIGHTS_PAGE:
        setActivePage(routesConfig.E_COACH_INSIGHTS_PAGE);
        handleClickedPage(routesConfig.E_COACH_INSIGHTS_PAGE);
        return history.push(routesConfig.E_COACH_INSIGHTS_PAGE);

      default:
        setActivePage(routesConfig.E_COACH_PAGE);
        handleClickedPage(routesConfig.E_COACH_PAGE);
        if (activeSession)
          return history.push({
            pathname: `${routesConfig.E_COACH_PAGE}/${activeSession.id}`,
          });
        return history.push({
          pathname: `${routesConfig.E_COACH_PAGE}`,
        });
    }
  };

  return (
    <Styled.Toolbar>
      {(activeSession || chatGuideData.inProgress) && (
        <Styled.ToolbarButton onClick={() => handleToolbarClick('new_chat')}>
          <Communication.MessagePlusSquare size={'15'} />
          {t('New chat')}
        </Styled.ToolbarButton>
      )}

      <Styled.ToolbarButton
        active={activePage === routesConfig.E_COACH_PAGE}
        onClick={() => handleToolbarClick(routesConfig.E_COACH_PAGE)}
      >
        <Communication.MessageChatSquare size={'15'} />
        {t('Chat')}
      </Styled.ToolbarButton>

      <Styled.ToolbarButton
        active={activePage === routesConfig.E_COACH_ALL_CHATS_PAGE}
        onClick={() => handleToolbarClick(routesConfig.E_COACH_ALL_CHATS_PAGE)}
      >
        <General.DotsGrid size={'15'} />
        {t('All chats')}
      </Styled.ToolbarButton>

      {user.is_team_lead && (
        <Styled.ToolbarButton
          active={activePage === routesConfig.E_COACH_INSIGHTS_PAGE}
          onClick={() => handleToolbarClick(routesConfig.E_COACH_INSIGHTS_PAGE)}
        >
          <MediaDevices.LightBulb05 size={'15'} />
          {t('insights.Insights')}
        </Styled.ToolbarButton>
      )}

      <Styled.ToolbarButton
        active={activePage === routesConfig.E_COACH_ABOUT_PAGE}
        onClick={() => handleToolbarClick(routesConfig.E_COACH_ABOUT_PAGE)}
      >
        <Files.FileQuestion02 size={'15'} />
        {t('About')}
      </Styled.ToolbarButton>
    </Styled.Toolbar>
  );
}

Toolbar.propTypes = {
  activeSession: PropTypes.object,
  chatGuideData: PropTypes.object,
  handleNewChat: PropTypes.func,
  handleClickedPage: PropTypes.func,
};
