import React, { useEffect } from 'react';

// components
import ApiLoading from 'components/_pageLayout/ApiLoading';
import ErrorBoundary from 'components/_pageLayout/ErrorBoundary';
import MemberJourneyList from 'components/pageJourneyList/MemberJourneyList';

// hooks
import useApi from 'hooks/useApi';

export default function JourneyListPage() {
  const { data, callApi: getJourneyList } = useApi();

  const journeyList = data;

  useEffect(() => {
    getJourneyList('/members/journeys/');
  }, [getJourneyList]);

  if (journeyList.status === 'loading') return <ApiLoading />;

  return (
    <ErrorBoundary>
      <MemberJourneyList journeyList={journeyList.response} />
    </ErrorBoundary>
  );
}
