import styled from 'styled-components';
import colours from 'constants/colours';
import * as Text from 'components/_styles/Text.style';

export const DividerWithText = styled('div')`
  align-items: center;
  display: flex;
  gap: 20px;
  justify-content: center;
  padding: 20px;
  position: relative;
`;

export const Divider = styled('div')`
  border-bottom: 1px ${colours.neutralWarm[300]} solid;
  flex: 1;
`;

export const DividerWithTextText = styled(Text.P2Semibold)`
  color: ${colours.neutralWarm[800]} !important;
`;
