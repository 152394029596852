import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// styles
import * as Styled from 'components/pageECoach/_styles/Divider.style';

function DividerWithText({ text }) {
  const { t } = useTranslation('pageEcoach');
  const content = text || t('Or');
  return (
    <Styled.DividerWithText>
      <Styled.Divider />
      <Styled.DividerWithTextText>{content}</Styled.DividerWithTextText>
      <Styled.Divider />
    </Styled.DividerWithText>
  );
}

export default DividerWithText;

DividerWithText.propTypes = {
  text: PropTypes.string,
};
