import styled from 'styled-components';
import device from 'constants/devices';

export const ContentContainer = styled('div')`
  align-items: center;
  display: flex;
  flex: 2;
  flex-direction: column;
  overflow: auto;
  padding-bottom: 30px;

  @media (max-width: ${device.tablet}) {
    overflow: auto;
  }
`;

export const Content = styled('div')`
  max-width: 1200px;
  min-width: 1000px;
  padding: 10px 60px;
  width: 100%;

  @media (min-width: ${device.laptopLMin}) {
    width: 90%;
  }

  @media (max-width: ${device.laptopL}) {
    max-width: unset;
    min-width: unset;
    padding: 0 0 60px 0;
    width: 90%;
  }
`;
